import Vue from 'vue'
import LanguageService from 'common/services/language.js'
import ExternalResource from 'common/services/resources/external.js'
import LocationResource from 'common/services/resources/location.js'
import FamilyResource from 'common/services/resources/family.js'
import SettingsResource from 'common/services/resources/settings.js'
import StorageService from 'common/services/storage.js'
import Scenarios from '../components/section/scenarios/scenarios.vue'
import ClMenu from 'common/components/menu/menu.vue'
import DeleteModal from 'common/components/delete-modal/delete-modal.vue'
import RoomsSelect from 'common/components/rooms-select/rooms-select.vue'
import NotificationsSelect from 'common/components/notifications-select/notifications-select.vue'
import RoomAdd from 'app/home/components/section/add-room/add-room.vue'
import IconList from 'common/components/icon-list/icon-list.vue'
import ServiceAdd from 'app/home/components/section/add-service/add-service.vue'
import sharesList from 'common/components/shares-list/shares-list.vue'
import { required, email} from 'vuelidate/lib/validators'
export default {
  components: {ClMenu, Scenarios,RoomsSelect,NotificationsSelect,RoomAdd,ServiceAdd,IconList,DeleteModal,sharesList},
  props: ['settings','externalAccounts',
          'devices', 'subDevices', 'users', 
          'plant', 'scenarios', 'models','defaultRooms',
          'icons','services','appConstants','serviceModels',
          'user','capabilities',
          'ownShares','devicesByCapability',
          'displayUserAddModal', 'userToEdit' //To user modal
          ],
  data:
      function () {
          return {
                    CONSTANTS:
                    {
                        ADD_ERROR : this.$gettext('Unable to add the user'),
                        ADD_SUCCESS : this.$gettext('The user has been successfully added'),
                        EMAIL_ALREADY_ERROR : this.$gettext('This email is already used for an account'),
                        DELETE_ERROR : this.$gettext('Unable to delete the user'),
                        DELETE_SUCCESS : this.$gettext('The user has been successfully deleted'),
                        ROOM_ERROR : this.$gettext('Unable to add the room'),
                        ROOM_ERROR_LEVEL : this.$gettext('You can\'t create a room at this level because it\'s already the smaller level'),
                        ROOM_SUCCESS : this.$gettext('The room has been successfully added'),
                        DELETE_ROOM_ERROR : this.$gettext('Unable to delete the room'),
                        DELETE_ROOM_SUCCESS : this.$gettext('The room has been successfully deleted'),
                        EDIT_DEVICE_ERROR : this.$gettext('Unable to edit the device'),
                        EDIT_DEVICE_SUCCESS : this.$gettext('The device has been successfully edited'),
                        DELETE_DEVICE_ERROR : this.$gettext('Unable to delete the device'),
                        DELETE_DEVICE_SUCCESS : this.$gettext('The device has been successfully deleted'),
                        EDIT_ROOM_ERROR : this.$gettext('Unable to edit the room'),
                        EDIT_ROOM_SUCCESS : this.$gettext('The room has been successfully edited'),
                        EDIT_PLANT_ERROR : this.$gettext('Unable to edit the plant'),
                        EDIT_PLANT_SUCCESS : this.$gettext('The plant has been successfully edited'),
                        NOT_YET : this.$gettext('Not available'),
                        INPUT_ERROR : this.$gettext('Please enter a name'),
                        EDIT_USER_ERROR : this.$gettext('Unable to edit the user'),
                        EDIT_USER_SUCCESS : this.$gettext('The user has been successfully edited'),
                        DELETE_USER_ERROR : this.$gettext('Unable to delete the user'),
                        DELETE_USER_SUCCESS : this.$gettext('The user has been successfully deleted'),
                        EDIT_SCENARIO_ERROR : this.$gettext('Unable to edit the scenario'),
                        EDIT_SCENARIO_SUCCESS : this.$gettext('The scenario has been successfully edited'),
                        DELETE_SCENARIO_ERROR : this.$gettext('Unable to delete the scenario'),
                        DELETE_SCENARIO_SUCCESS : this.$gettext('The scenario has been successfully deleted'),
                        EDIT_SERVICE_ERROR : this.$gettext('Unable to edit the kit'),
                        EDIT_SERVICE_SUCCESS : this.$gettext('The kit has been successfully edited'),
                        DELETE_SERVICE_ERROR : this.$gettext('Unable to delete the kit'),
                        DELETE_SERVICE_SUCCESS : this.$gettext('The kit has been successfully deleted'),
                        ADD_USER_TITLE : _upperFirst(this.$gettext('add a user')),
                        EDIT_USER_TITLE : this.$gettext('edit a user'),
                        VALIDATE_ERROR : this.$gettext('Unable to validate'),
                        CANNOT_DELETE_LAST_ROOM : this.$gettext('You can not delete your last room'),
                        EDIT_ROOM_TITLE : this.$gettext('Edit a room'),
                        EDIT_MULTI_TEXT : this.$gettext('If you move <b>%{s1}</b> :<br/><b>%{s2}</b>, <b>%{s3}</b> and <b>%{s4}</b> will also move to <b>%{s5}</b>.'),
                        SYNCHRONIZE_ERROR : this.$gettext('Unable to synchronize the account'),
                        SYNCHRONIZE_SUCCESS : this.$gettext('The account has been successfully synchronized'),
                        NOTIFICATIONS : _upperFirst(this.$gettext('notifications')),
                        DISPLAY : _upperFirst(this.$gettext('display in rooms')),
                        NO_NETWORK: this.$gettext('Inactive internet connection! Please activate your internet connection in order to execute this action'),
                        NOT_ADMIN: this.$gettext('You need to be an administrator to edit or delete a user'),
                        ACCOUNT:  this.$gettext('%{s} account'),
                        NOTIFICATIONS_DEVICES: this.$gettext('Would you like to receive <b>notifications</b> of this device ?'),
                       
                        DEFAULT_VIEW_MODE : _upperFirst(this.$gettext('default mode:')),
                        DEFAULT_VIEW_MODE_TABLET : _upperFirst(this.$gettext('tablet')),
                        DEFAULT_VIEW_MODE_ROOMS : _upperFirst(this.$gettext('Mobile phone')),

                        DASHBOARD_GENERAL_PARAMETERS: _upperFirst(this.$gettext('Tablet:')),

                        CAMERA_GENERAL_PARAMETERS: _upperFirst(this.$gettext('Camera:')),

                        PASSWORD_DONT_MATCH : this.$gettext('The confirmation password don\'t match'),
                        PASSWORD_TOO_SHORT : this.$gettext('Your password must contain at least 8 characters'),
                        ROLES:
                        [
                          {
                            text: this.$gettext('Administrator'),
                            value: 'ADMIN'
                          },
                          {
                            text: this.$gettext('Standard user'),
                            value: 'USER'
                          }
                        ],
                        EDIT_DEVICE_MODAL:
                        {
                          alert:[],
                          zoneId:''
                        }
                    },
                    nameRoom: '',
                    namePlant: '',
                    member: {
                      "login" : '',
                      "firstname" : '',
                      "lastname" : '',
                      "oldPassword" : '',
                      "password" : '',
                      "confirm" : '',
                      "role" : 'Administrator'
                    },
                    showPwd : false,
                    showPwdConfirm: false,
                    showOldPwd: false,
                    selectedExternalAccount:{},
                    selectedRoom:{
                      level:'ZONE',
                      name:''
                    },
                    selectedDevice:{},
                    selectedScenario:{},
                    showUser : false,
                    showRoom : false,
                    showEquipment : false,
                    showScenario : false,
                    showService : false,
                    showAccount : false,
                    color : false,
                    userModal:{
                      visible: false,
                      title:''
                    },
                    deleteExternalAccountModal:{
                      visible: false
                    },
                    addRoomModal:{
                      visible: false
                    },
                    editPlantModal:{
                      visible: false
                    },
                    deleteRoomModal:{
                      visible: false
                    },
                    deleteUserModal:{
                      visible: false
                    },
                    editScenarioModal:{
                      visible: false,
                      visibility: '',
                      scenario:{},
                      zoneId:'',
                      index:0,
                      notification:''
                    },
                    editServiceModal:{
                      visible: false,
                      visibility: '',
                      service:{},
                      zoneId:'',
                      //notification:'',
                      //alert:[]
                    },
                    scenariosModal: false,
                    serviceAddModal: false,
                    deleteScenarioModal:{
                      visible: false
                    },
                    deleteServiceModal:{
                      visible: false
                    },
                    deleteDeviceModal:{
                      visible: false
                    },
                    editDeviceModal:
                    {
                      alert:[]
                    },
                    editDeviceModalVisible:false,
                    moveMultiModal:{
                      visible: false
                    },
                    editIconModal:{
                      visible: false
                    },
                    modalSettingsDeleteConfirm:{
                      content:''
                    },
                    closeConfirmDialog:false,
                    loading: false,
                    externalAccountLoading: [],
                    addRoom:{
                      title:'',
                      selectedRoom:{}
                    },
                    config:{
                      sectionName:'settings'
                    },
                    zoneName : '',
                    multiText : '',
                    //The tab is recover with a ws in the main.js
                    iconTab : [],
                    iconModel : {}
          }
  },
	validations: {
    member: {
      login: {
        required,
        email
	  	}
    }
	},
  computed: {
		computedUsers: function(){
			return (this.$store.state.bMainAccount?this.users:{members:[{login:this.$store.state.userMail}]});
		},
    orderedZones: function () {
      var _this = this;
      var zones = _orderBy(this.plant.zones, function(room) { return _this.$gettext(room.name).toLowerCase()});
      zones.forEach(function(room,index) {
        if(room.modules)
        {
          var modules  = _orderBy(room.modules, function(module) { return module.name.toLowerCase()});
          zones[index].modules = modules;
        }
      });
      return zones;
    },
    orderedScenarios: function () {
      var _this = this;
      var zones = _orderBy(this.plant.zones, function(room) { return _this.$gettext(room.name).toLowerCase()});
      let scenarios = [];
      zones.forEach(function(room,index) {
        if(room.modules)
        {
          room.modules.forEach(function(module,index){
            if(module.scenario)
            {
              scenarios.push(module);
            }
          });
        }
      });
      return _orderBy(scenarios, function(module) { return module.name.toLowerCase()});
    },

    orderedExternalAccounts: function () {
      this.$store.state.selectedExternalAccounts = this.$store.state.selectedExternalAccounts.map(function(se,index) {
                        
        se.loading=false;
        return se;
      });
      return _orderBy(this.$store.state.selectedExternalAccounts, function(externalAccount) { return externalAccount.external.displayName.toLowerCase()});
    },

    convertFullscreenCameraDisplayDuration : function(){
      var min = Math.trunc(parseInt(this.settings.fullscreenCameraDisplayDuration)/60);
      var sec = parseInt(this.settings.fullscreenCameraDisplayDuration)%60;
      return{ 
        m: (min<10?'0':'')+min,
        s: (sec<10?'0':'')+sec
      }
    },
  
    convertViewAutoplayDelay : function(){
      var min = Math.trunc(parseInt(this.settings.viewAutoplayDelay)/60);
      var sec = parseInt(this.settings.viewAutoplayDelay)%60;
      return{ 
        m: (min<10?'0':'')+min,
        s: (sec<10?'0':'')+sec
      }
    },
  },

  created: function () {
      this.eventHub.$on('scenariosDialogEdit', this.scenariosDialogEdit);
      this.eventHub.addEvent('closeDialog',this.closeDialog);
      this.eventHub.$on('deleteRoom',this.deleteRoom);
      this.eventHub.$on('setDeviceIcon', this.setDeviceIcon);
      this.eventHub.$on('openDialog', this.openDialog);

      this.$nextTick(function() {
        this.initIcon();
      });
  },
  mounted: function () {
    if(this.displayUserAddModal == true){
      this.openDialog((this.userToEdit ? 'editUser-dialog': 'addUser-dialog'),this.userToEdit );
    }
  },
  destroyed: function () {
      this.eventHub.$off('setDeviceIcon', this.setDeviceIcon);
      this.eventHub.$off('openDialog', this.openDialog);
  },

  methods:
  {
    /*GLOBAL*/

    editModule: function(coal, coalId, data, callbackfunc, modalRef, alertMsgs)
    {
      var _this = this;
      this.loading = true;
      LocationResource.edit(coal, coalId, data).then(
      function(response) {
          _this.loading = false;
          callbackfunc();
          if(modalRef == "editDeviceModal")
            _this.editDeviceModalVisible = false;
          else
            modalRef.visible = false;
          _this.eventHub.$emit('displayAlert', alertMsgs.success, 'success');
          //_this.eventHub.$emit('changeOrientation', 'user');
      }).catch(
        function(e) {
          if(e == 'Error: Network Error')
              _this.eventHub.$emit('displayAlert', _this.CONSTANTS.NO_NETWORK);
          else if(e.response && e.response.status == 401)
              _this.eventHub.$emit('logout');
          else
              _this.eventHub.$emit('displayAlert', alertMsgs.error);
          _this.loading = false;
          //_this.eventHub.$emit('changeOrientation', 'user');
      });
    },

    deleteModule: function(coal, coalId, callbackfunc, modalRef, alertMsgs)
    {
        var _this = this;
        LocationResource.deleteLocationTopology(coal, coalId).then(
        function(response) {
            callbackfunc();
            modalRef.visible = false;
            _this.eventHub.$emit('displayAlert', alertMsgs.success, 'success');
            _this.eventHub.$emit('setLoadingValidate', false);
            _this.eventHub.$emit('setLoading',false);
        }).catch(
          function(e) {
            if(e == 'Error: Network Error')
              _this.eventHub.$emit('displayAlert', _this.CONSTANTS.NO_NETWORK);
            else if(e.response && e.response.status == 401)
              _this.eventHub.$emit('logout');
            else
              _this.eventHub.$emit('displayAlert', alertMsgs.error);
            _this.eventHub.$emit('setLoading',false);
            _this.eventHub.$emit('setLoadingValidate', false);
        });
    },

    notAvailable: function(){
          this.eventHub.$emit('displayAlert', this.CONSTANTS.NOT_YET);
    },

    /*EXTERNAL ACCOUNT*/

    deleteExternalAccount : function(){
        var _this=this;
        ExternalResource.delete(this.selectedExternalAccount.external.name).then(
          function(response) {
            _this.eventHub.$emit('setLoading',false);
            _this.eventHub.$emit('getAllAccount');
            _this.deleteExternalAccountModal.visible = false;
            _this.eventHub.$emit('displayAlert', _this.CONSTANTS.DELETE_SUCCESS, 'success');
          }).catch(
          function(e) {
            if(e.response.status == 401)
                _this.eventHub.$emit('logout');
            else
                _this.eventHub.$emit('displayAlert', _this.CONSTANTS.DELETE_ERROR);
            _this.eventHub.$emit('setLoading',false);
                  });
    },

    synchronize : function(externalAccount){
      var _this = this;
      Vue.set(this.externalAccountLoading, externalAccount.external.name, true);
      ExternalResource.synchronize(externalAccount.external.name).then(
        function() {
            _this.eventHub.$emit('getAllTopology', 'all');
            _this.eventHub.$emit('displayAlert', _this.CONSTANTS.SYNCHRONIZE_SUCCESS, 'success');
            Vue.set(_this.externalAccountLoading, externalAccount.external.name, false);
        }).catch(
          function(e) {
            if(e.response.status == 401)
              _this.eventHub.$emit('logout');
            else
              _this.eventHub.$emit('displayAlert', _this.CONSTANTS.SYNCHRONIZE_ERROR);
            Vue.set(_this.externalAccountLoading, externalAccount.external.name, false);
        });
    },

    /*PLANT*/
    editPlant : function(ref){
      var _this = this;
      if(_this.namePlant == ''){
          _this.eventHub.$emit('displayAlert',  _this.CONSTANTS.INPUT_ERROR);
      }
      else{
        this.loading = true;
        LocationResource.editPlant(_this.namePlant).then(
          function(response) {
            _this.loading = false;
            _this.namePlant = '';
            _this.eventHub.$emit('getAllTopology', 'all');
            _this.editPlantModal.visible = false;
            _this.eventHub.$emit('displayAlert', _this.CONSTANTS.EDIT_PLANT_SUCCESS, 'success');
          }).catch(
            function() {
              _this.loading = false;
              _this.eventHub.$emit('displayAlert', _this.CONSTANTS.EDIT_PLANT_ERROR);
            });
      }
    },

    /*ROOMS*/

    deleteRoom: function(){
      this.openDialog('deleteRoom-dialog',this.selectedRoom);
    },

    doNotConfirmDeleteRoom: function(){
      this.deleteRoomModal.visible = false;
      this.eventHub.$emit('setLoadingValidate',  false);
    },

    confirmDeleteRoom: function(){
      if(this.plant.zones.length==1)
      {
        this.eventHub.$emit('displayAlert',  this.CONSTANTS.CANNOT_DELETE_LAST_ROOM);
        return false;
      }
      var _this=this;
      this.deleteModule('zone', this.addRoom.selectedRoom.id,
                        function(){
                          _this.deleteRoomModal.visible = false;
                          _this.eventHub.$emit('getAllTopology', 'all');
                        },
                        this.addRoomModal,
                        {'success':_this.CONSTANTS.DELETE_ROOM_SUCCESS, 'error':_this.CONSTANTS.DELETE_ROOM_ERROR});
    },

    /*DEVICE*/

    editDevice : function(device){
      if(!this.eventHub.displayFeatureNotAvailableSharedAccount())
      {
        return false;
      }
      this.selectedDevice = device;
      this.editDeviceModal = JSON.parse(JSON.stringify(this.CONSTANTS.EDIT_DEVICE_MODAL));
      try
      {
        _merge(this.editDeviceModal ,JSON.parse(JSON.stringify(device)));
      }
      catch(e)
      {
        var cls = device.device.class;
        delete device.device.class;
        _merge(this.editDeviceModal ,JSON.parse(JSON.stringify(device)));
        device.device.class = cls;
        this.editDeviceModal.device.class = cls;
      }
      this.editDeviceModalVisible = true;
      this.eventHub.$emit('changeOrientation', 'portrait');
    },

    sortEditdevice : function(){
      //We look if it's a multisocket to show a message if it's one and the location change
      var _this = this;
      if(this.selectedDevice.idMulti && this.editDeviceModal.zoneId != this.selectedDevice.zoneId){
        var sockets = _filter(this.devices, function(o) { return o.idMulti == _this.selectedDevice.idMulti; });
        var otherSockets = [];
        for(var i=0; i<sockets.length; i++){
          if(sockets[i].id != this.selectedDevice.id){
            otherSockets.push(sockets[i].name);
          }
        }
        var indexZone = _findIndex(this.plant.zones, function(o) { return o.id == _this.editDeviceModal.zoneId; });
        this.zoneName = this.plant.zones[indexZone].name;
        var obj = {
          's1' : this.selectedDevice.name,
          's2' : otherSockets[0],
          's3' : otherSockets[1],
          's4' : otherSockets[2],
          's5' : this.$gettext(this.zoneName)
        };
        this.multiText = this.$gettextInterpolate(this.CONSTANTS.EDIT_MULTI_TEXT, obj);
        this.moveMultiModal.visible = true;
      }
      //We look if it's a multisocket to construct 2 obj and not show the message if the location doesn't change
      else if(this.selectedDevice.idMulti && this.editDeviceModal.zoneId == this.selectedDevice.zoneId){
        this.construcObj('multi');
      }
      else{
        //If it's a plug we construct an object different because in one case we need plug id and in the other case we need subsocket id
        if(this.selectedDevice.idPlug){
          this.construcObj('plug');
        }
        else{
          //We call the normal function for all other devices
          var obj = {};
          if(this.editDeviceModal.name != this.selectedDevice.name)
            obj.name = this.editDeviceModal.name;
          //if(this.editDeviceModal.zoneId != this.selectedDevice.zoneId)
            obj.containerId = this.editDeviceModal.zoneId;
          if(this.$refs["notifications-select"] && this.$refs["notifications-select"].notification.notification_level != this.selectedDevice.notification_level){
            obj.notificationLevel = this.$refs["notifications-select"].notification.notification_level;
            this.editDeviceModal.notification_level = this.$refs["notifications-select"].notification.notification_level;
          }
          obj.alert = [];
          if(this.$refs["notifications-select"] && this.$refs["notifications-select"].notification.notification_level == 'ALERT' && this.$refs["notifications-select"].notification.alert != this.selectedDevice.alert)
            obj.alert = this.$refs["notifications-select"].notification.alert;
          if(this.editDeviceModal.icon != null){
            if(this.editDeviceModal.icon != this.selectedDevice.icon)
              obj.icon = this.editDeviceModal.icon;
          }
          this.editDeviceFinished(obj);
        }
      }
    },

    construcObj : function(id){

      var allPromise = [];
      var obj = {};
      var _this = this;
      this.loading = true;
      //Call the ws if name or icon are different with the subsocket id
      if(this.editDeviceModal.icon != this.selectedDevice.icon || this.editDeviceModal.name != this.selectedDevice.name){
        obj = {name: this.editDeviceModal.name,icon:this.editDeviceModal.icon};
        allPromise.push(LocationResource.edit('module', this.selectedDevice.id, obj));
      }
      //Call the ws if zone is different with the plug or multi id
      if(this.editDeviceModal.zoneId != this.selectedDevice.zoneId){
        obj = {containerId:this.editDeviceModal.zoneId};
        this.moveMultiModal.visible = false;
        allPromise.push(LocationResource.edit('module', this.selectedDevice.idModule, obj));
      }
      if(allPromise.length > 0){
        Promise.all(allPromise).then(
          function(){
            _this.editDeviceModalVisible = false;
            _this.eventHub.$emit('getAllTopology', 'all');
            _this.loading = false;
            _this.eventHub.$emit('displayAlert', _this.CONSTANTS.EDIT_DEVICE_SUCCESS, 'success');
          }).catch(
          function() {
            _this.eventHub.$emit('displayAlert', _this.CONSTANTS.EDIT_DEVICE_ERROR);
            _this.loading = false;
          });
      }
      else{
        this.editDeviceModalVisible = false;
        this.loading = false;
      }
    },

    reloadRoom(){
      this.moveMultiModal.visible = false;
      this.editDeviceModal.zoneId = this.selectedDevice.zoneId;
    },

    //Function to call the ws with the parameters
    editDeviceFinished : function(obj){
      if(Object.keys(obj).length != 0){
        var _this = this;
        this.editModule('module', this.selectedDevice.id, obj,
        function(){_this.eventHub.$emit('getAllTopology', 'all');},
        "editDeviceModal",
        {'success':this.CONSTANTS.EDIT_DEVICE_SUCCESS, 'error':this.CONSTANTS.EDIT_DEVICE_ERROR});
      }
      else{
        this.editDeviceModalVisible = false;
        //this.eventHub.$emit('changeOrientation', 'user');
      }
    },

    changeDeviceRoom : function(zone){
      this.editDeviceModal.zoneId = zone.id;
    },

    deleteDevice : function(){
      var _this = this;
      this.deleteModule('module', _this.selectedDevice.id,
                function(){_this.eventHub.$emit('getAllTopology', 'all');},
                this.deleteDeviceModal,
                {'success':_this.CONSTANTS.DELETE_DEVICE_SUCCESS, 'error':_this.CONSTANTS.DELETE_DEVICE_ERROR});
    },

    setDeviceIcon : function(value){
      this.editDeviceModal.icon = value;
      this.editIconModal.visible = false;
    },

    /*activeDisplayDevice : function(){
      this.editDeviceModal.visibility = (this.editDeviceModal.visibility=='DEFAULT'?'NONE':'DEFAULT');
    },*/

    notificationsAvailable : function(){
      if(this.editDeviceModal.device.brandObj){
        var type = this.editDeviceModal.device.brandObj.wizard.type;
        if(type != 'INTERNAL' && type != 'UNDEFINED' || this.editDeviceModal.device.model == 'homepilot'){
          return false
        }
        else{
          return true
        }
      }
      return false
    },

    /*USER*/
    validateUser : function(type){
      if(this.$v.$invalid)
      {
        this.eventHub.$emit('displayAlert', this.CONSTANTS.VALIDATE_ERROR);
        return false;
      }
      else if(this.member.password!=this.member.confirm)
      {
        this.eventHub.$emit('displayAlert', this.CONSTANTS.PASSWORD_DONT_MATCH);
        return false;
      }
      else if(this.member.password.length<8)
      {
        this.eventHub.$emit('displayAlert', this.CONSTANTS.PASSWORD_TOO_SHORT);
        return false;
      }
      if(type=='add')
        this.addUser();
      else
        this.editUser();
    },
    addUser : function(){
      var _this = this;
      if(this.member.password == this.member.confirm){
        var member = {
                'login' : this.member.login,
                'password' : this.member.password,
                'firstname' : this.member.firstname,
                'lastname' : this.member.lastname,
                'app': StorageService.getValue(localStorage, 'app'),
                'roles' : [this.member.role],
                'language': LanguageService.getUserLanguage()
              };
        this.loading=true;
        FamilyResource.addMember(member, this.users.id).then(
          function(response){
            _this.loading=false;
            _this.userModal.visible = false;
            _this.eventHub.$emit('getUsers');
            _this.eventHub.$emit('displayAlert', _this.CONSTANTS.ADD_SUCCESS, 'success');
            //_this.eventHub.$emit('changeOrientation', 'user');
          }).catch(
            function(e) {
              _this.loading=false;
              if(e == 'Error: Network Error')
                _this.eventHub.$emit('displayAlert', _this.CONSTANTS.NO_NETWORK);
              else if(e.response && e.response.status == 401)
                _this.eventHub.$emit('logout');
              else if(e.response && [402].indexOf(e.response.status)!=-1)
								_this.eventHub.$emit('displayAlertKey', 'unauthorized');  
              else if(e.response && e.response.status == 409)
                _this.eventHub.$emit('displayAlert', _this.CONSTANTS.EMAIL_ALREADY_ERROR);
              else
                _this.eventHub.$emit('displayAlert', _this.CONSTANTS.ADD_ERROR);
              //_this.eventHub.$emit('changeOrientation', 'user');
          });
        }
    },
    editUser : function(){
      var _this = this;
      var member = {
              'firstname' : this.member.firstname,
              'lastname' : this.member.lastname,
              'roles' : [this.member.role]
            };
      if(this.member.password.length!=0)
      {

        member.password = {
          new: this.member.password,
          old: this.member.oldPassword
        }
      }
      this.loading=true;
      FamilyResource.editMember(member, this.users.id, this.member.authorization).then(
        function(response){
          _this.loading=false;
          _this.userModal.visible = false;
          _this.eventHub.$emit('getUsers');
          _this.eventHub.$emit('displayAlert', _this.CONSTANTS.EDIT_USER_SUCCESS, 'success');
          //_this.eventHub.$emit('changeOrientation', 'user');
        }).catch(
        function(e) {
          if(e == 'Error: Network Error')
            _this.eventHub.$emit('displayAlert', _this.CONSTANTS.NO_NETWORK);
          /*else if(e.response.status == 401)
            _this.eventHub.$emit('logout');*/
          else
            _this.eventHub.$emit('displayAlert', _this.CONSTANTS.EDIT_USER_ERROR);
          _this.loading=false;
          //_this.eventHub.$emit('changeOrientation', 'user');
      });
    },

    deleteUser : function(){
      var _this = this;
      FamilyResource.deleteMember(this.users.id, this.member.authorization).then(
        function(response){
          _this.eventHub.$emit('setLoading',false);
          _this.deleteUserModal.visible=false;
          _this.eventHub.$emit('getUsers');
          _this.eventHub.$emit('displayAlert', _this.CONSTANTS.DELETE_USER_SUCCESS, 'success');
        }).catch(
        function(e) {
          if(e == 'Error: Network Error')
              _this.eventHub.$emit('displayAlert', _this.CONSTANTS.NO_NETWORK);
          else if(e.response && e.response.status == 401)
              _this.eventHub.$emit('logout');
          else
              _this.eventHub.$emit('displayAlert', _this.CONSTANTS.DELETE_USER_ERROR);
          _this.eventHub.$emit('setLoading',false);
        });
    },

    /*SCENARIO*/

    editScenario : function(scenario){
      if(!this.eventHub.displayFeatureNotAvailableSharedAccount())
      {
        return false;
      }
      var s = JSON.parse(JSON.stringify(scenario));
      this.selectedScenario = s;
      this.editScenarioModal.scenario = s;
      this.editScenarioModal.scenario.actions = this.getScenarioActionTrigger(s.scenario.actions);
      this.editScenarioModal.scenario.triggers = this.getScenarioActionTrigger(s.scenario.triggers);
      this.editScenarioModal.index = _findIndex(this.scenarios, function(o) { return o.id == s.id; });
      this.editScenarioModal.visible = true;
      this.eventHub.$emit('changeOrientation', 'portrait');
      this.editScenarioModal.visibility = s.visibility;
      this.editScenarioModal.notification = s.notification_level;
      //this.editScenarioModal.scenario.locationId = roomId;
      //this.editScenarioModal.zoneId = roomId;
    },

    getScenarioActionTrigger(aORt){
      if(aORt){
        for(var i=0; i<aORt.length; i++){
          delete aORt[i].id;
          delete aORt[i].infos.type;
          if(aORt[i].infos.id)
            aORt[i].infos.moduleId = aORt[i].infos.id;
            delete aORt[i].infos.id;
          }
          return aORt;
      }
    },

    editScenarioFinished : function(){
      var _this = this;
      /*add visibility: this.editScenarioModal.visibility*/
      this.editModule('module', this.selectedScenario.id, {name: this.editScenarioModal.scenario.name, notificationLevel: this.editScenarioModal.notification},
      function(){_this.eventHub.$emit('getAllTopology', 'all');},
      this.editScenarioModal,
      {'success':this.CONSTANTS.EDIT_SCENARIO_SUCCESS, 'error':this.CONSTANTS.EDIT_SCENARIO_ERROR});
    },

    editScenarioContain : function(){
      this.editScenarioModal.visible = false
      this.scenariosModal = true;
    },

    scenariosDialogEdit : function(){
      this.scenariosModal = false;
      this.eventHub.$emit('getAllTopology', 'all');
    },

    activeNotificationScenario : function(){
      this.editScenarioModal.notification = (this.editScenarioModal.notification=='INFO'?'NONE':'INFO');
    },

    /*activeDisplayScenario : function(){
      this.editScenarioModal.visibility = (this.editScenarioModal.visibility=='DEFAULT'?'NONE':'DEFAULT');
    },*/

    deleteScenario : function(){
        var _this = this;
        this.deleteModule('module', _this.selectedScenario.id,
                    function(){
                      var index = _findIndex(_this.scenarios, function(o) { return o.id == _this.selectedScenario.id });
                      _this.scenarios.splice(index, 1);
                      for(var i=0; i<_this.plant.zones.length; i++){
                        if(_findIndex(_this.plant.zones[i].modules, function(o) { return o.id == _this.selectedScenario.id }) != -1){
                          var index2 = _findIndex(_this.plant.zones[i].modules, function(o) { return o.id == _this.selectedScenario.id });
                          _this.plant.zones[i].modules.splice(index2, 1);
                          break;
                        }
                      }
                    },
                    this.deleteScenarioModal,
                    {'success':_this.CONSTANTS.DELETE_SCENARIO_SUCCESS, 'error':_this.CONSTANTS.DELETE_SCENARIO_ERROR});
    },

    changeScenarioRoom : function(zone){
      this.editScenarioModal.zoneId = zone.id;
    },

    /*SERVICES*/

    editService : function(service, roomId){
      if(!this.eventHub.displayFeatureNotAvailableSharedAccount())
      {
        return false;
      }
      var s = JSON.parse(JSON.stringify(service));
      this.editServiceModal.service = s;
      this.editServiceModal.service.zoneId = roomId;
      this.editServiceModal.zoneId = roomId;
      this.editServiceModal.visible = true;
      this.eventHub.$emit('changeOrientation', 'portrait');
      /*this.editServiceModal.notification = service.notification_level;
      if(service.alert)
        this.editServiceModal.alert = service.alert;*/
    },

    openEditService : function(){
      this.eventHub.$emit('openEditService', this.editServiceModal.service);
      this.editServiceModal.visible = false;
    },

    editServiceFinished : function(){
      var _this = this;
      /*add visibility: _this.editServiceModal.visibility*/
      this.editModule('module', this.editServiceModal.service.id, {name: _this.editServiceModal.service.name, containerId: _this.editServiceModal.service.zoneId},
      function(){
        _this.eventHub.$emit('getAllTopology', 'all');
        /*var index = _findIndex(_this.services, function(o) { return o.id == _this.editServiceModal.service.id; });
        _this.services[index].name = _this.editServiceModal.service.name;
        _this.services[index].zoneId = _this.editServiceModal.zoneId;*/
      },
      this.editServiceModal,
      {'success':this.CONSTANTS.EDIT_SERVICE_SUCCESS, 'error':this.CONSTANTS.EDIT_SERVICE_ERROR});
    },

    deleteService : function(){
        var _this = this;
        this.deleteModule('module', _this.editServiceModal.service.id,
        function(){_this.eventHub.$emit('getAllTopology', 'all');},
        this.deleteServiceModal,
        {'success':this.CONSTANTS.DELETE_SERVICE_SUCCESS, 'error':this.CONSTANTS.DELETE_SERVICE_ERROR});
    },

    changeServiceRoom : function(zone){
      this.editServiceModal.zoneId = zone.id;
      this.editServiceModal.service.zoneId = zone.id;
    },

    /*activeDisplayService : function(){
      this.editServiceModal.visibility = (this.editServiceModal.visibility=='DEFAULT'?'NONE':'DEFAULT');
    },*/

    /*addServiceFinished: function(service){
      this.serviceAddModal=false;
      this.editServiceModal.service.name = service.name;
      //this.editServiceModal.notification = service.notificationLevel;
      this.editServiceModal.zoneId = service.locationId;

      this.editServiceModal.service.service.name = service.name;
      //this.editServiceModal.service.service.notification_level = service.notificationLevel;
      this.editServiceModal.service.service.infos = service.infos;
      this.editServiceModal.service.service.zoneId = service.locationId;
    },*/

    /*MODAL*/
    openDialog : function(ref,obj){
      if(!this.eventHub.displayFeatureNotAvailableSharedAccount())
      {
        return false;
      }
      switch(ref)
      {
          case 'deleteExternalAccount-dialog':
              if(obj.account.name)
                this.modalSettingsDeleteConfirm.content= this.$gettextInterpolate(this.$gettext('Are you sure you want to delete user \'%{s}\'?'), {s: obj.account.name});
              else
                this.modalSettingsDeleteConfirm.content= this.$gettextInterpolate(this.$gettext('Are you sure you want to delete account \'%{s}\'?'), {s: obj.external.displayName});
              this.selectedExternalAccount = obj;
              this.deleteExternalAccountModal.visible = true;
              break;
          case 'addRoom-dialog':
              this.addRoom.title = "add a new room";
              this.addRoom.selectedRoom = undefined;
              this.addRoomModal.visible = true;
              break;
          case 'deleteRoom-dialog':
              this.addRoomModal.visible = false;
              this.modalSettingsDeleteConfirm.content=this.$gettextInterpolate(this.$gettext('Are you sure you want to delete the room \'%{s}\'?'), {s: this.$gettext(this.addRoom.selectedRoom.name) });
              this.deleteRoomModal.visible = true;
              break;
          case 'editRoom-dialog':
              this.addRoomModal.visible = true;
              this.addRoom.title = this.CONSTANTS.EDIT_ROOM_TITLE;
              this.addRoom.selectedRoom = obj;
              break;
          case 'deleteDevice-dialog':
              this.editDeviceModalVisible = false;
              this.modalSettingsDeleteConfirm.content=this.$gettextInterpolate(this.$gettext('Are you sure you want to delete the device \'%{s}\'?'), {s: this.selectedDevice.name});
              this.deleteDeviceModal.visible = true;
              break;
          case 'addUser-dialog':
                this.member = {
                  "login" : '',
                  "firstname" : '',
                  "lastname" : '',
                  "oldPassword" : '',
                  "password" : '',
                  "confirm" : '',
                  "role" : 'Administrator'
                };
                this.eventHub.$emit('changeOrientation', 'portrait');
                this.modalSettingsDeleteConfirm.content='';
                this.userModal =
                {
                  visible : true,
                  type : 'add',
                  title: this.CONSTANTS.ADD_USER_TITLE
                };
                break;
          case 'editUser-dialog':
                if(StorageService.getValue(localStorage, 'role') == null){
                  this.eventHub.$emit('logout');
                }
                else if(StorageService.getValue(localStorage, 'role') == 'ADMIN'){
                  this.member.password = '';
                  this.member.oldPassword = '';
                  this.member.confirm = '';
                  for (var x in obj) {
                    this.member[x]=obj[x];
                  }
                  this.member.role = obj.roles.toString();
                  this.modalSettingsDeleteConfirm.content='';
                  this.eventHub.$emit('changeOrientation', 'portrait');
                  this.userModal =
                  {
                    visible : true,
                    type : 'edit',
                    title: this.CONSTANTS.EDIT_USER_TITLE
                  };
                }
                else{
                  this.eventHub.$emit('displayAlert', this.CONSTANTS.NOT_ADMIN);
                }
                break;
          case 'deleteScenario-dialog':
                this.editScenarioModal.visible = false;
                this.modalSettingsDeleteConfirm.content=this.$gettextInterpolate(this.$gettext('Are you sure you want to delete the scenario \'%{s}\'?'), {s: this.selectedScenario.name});
                this.deleteScenarioModal.visible = true;
                break;
          case 'deleteService-dialog':
                this.editServiceModal.visible = false;
                this.modalSettingsDeleteConfirm.content=this.$gettextInterpolate(this.$gettext('Are you sure you want to delete the kit \'%{s}\'?'), {s: this.editServiceModal.service.name});
                this.deleteServiceModal.visible = true;
                break;
          case 'deleteUser-dialog':
                this.userModal.visible = false;
                this.modalSettingsDeleteConfirm.content=this.$gettextInterpolate(this.$gettext('Are you sure you want to delete the user \'%{s}\'?'), {s: this.member.login});
                this.deleteUserModal.visible = true;
                break;
      }
      if(this.$refs[ref])
        this.$refs[ref].open();
    },

    closeModalEdition: function(modal){
      modal.visible = false;
      //this.eventHub.$emit('changeOrientation', 'user');
    },

    closeDialog: function(){
      this.addRoomModal.visible = false;
    },

    /*EXPENSABLE LIST*/
    show: function(categorie){
      switch(categorie){
        case 'user' : this.showUser = true;
                      break;
        case 'room' : this.showRoom = true;
                      break;
        case 'equipment' : this.showEquipment = true;
                            break;
        case 'scenario' : this.showScenario = true;
                          break;
        case 'service' : this.showService = true;
                          break;
        case 'account' : this.showAccount = true;
                          break;
      }
    },
    remove: function(categorie){
      switch(categorie){
        case 'user' : this.showUser = false;
                      break;
        case 'room' : this.showRoom = false;
                      break;
        case 'equipment' : this.showEquipment = false;
                            break;
        case 'scenario' : this.showScenario = false;
                          break;
        case 'service' : this.showService = false;
                          break;
        case 'account' : this.showAccount = false;
                          break;
      }
    },
    initIcon: function(){
      this.iconTab = LocationResource.getIconsName(this.icons);
      this.iconModel = LocationResource.getKeyForModel();
    },
    isModelIcon: function(){
      if(this.iconModel[this.editDeviceModal.device.model] != undefined){
        return true;
      }
    },
    closeConfirmDialogValidate: function(){
      this.closeConfirmDialog=false;
			this.scenariosModal=false;
      this.serviceAddModal=false;
      this.eventHub.$emit('setLoading',false);
    },
    moduleInRoom : function(room, type){
      if(room.modules){
        for(var i=0; i<room.modules.length; i++){
          if(room.modules[i][type])
            return true
        }
      }
      return false;
    },

    /*GENERAL*/

    setDefaultView : function(defaultView){
      SettingsResource.setDefaultView(defaultView);
    },

    setCameraFrameRate : function(cameraFrameRate){
      SettingsResource.setCameraFrameRate(cameraFrameRate);
    },

    setFullscreenCameraDisplayDuration : function(){
      var fullscreenCameraDisplayDuration  = 60*parseInt(this.convertFullscreenCameraDisplayDuration.m)+parseInt(this.convertFullscreenCameraDisplayDuration.s);
      SettingsResource.setFullscreenCameraDisplayDuration(fullscreenCameraDisplayDuration);
    },

    setViewAutoplayDelay: function(){
      var viewAutoplayDelay  = 60*parseInt(this.convertViewAutoplayDelay.m)+parseInt(this.convertViewAutoplayDelay.s);
      SettingsResource.setViewAutoplayDelay(viewAutoplayDelay);
    },

  }
}