import Vue from 'vue'
import DeleteModal from 'common/components/delete-modal/delete-modal.vue'
import ShareResource from 'common/services/resources/share.js'
import StorageService from 'common/services/storage.js'
import moment from 'moment'
var stringify = require('json-stringify-safe');
export default {
	components: {DeleteModal},
	props: {
		title: {
			type: String,
			default: ''
		},
		plant: {
			type: Object,
			default: {}
		},
		shares: {
			type: Array,
			default: []
		},	
	},
	data:
		function () {
			return {
					CONSTANTS:
					{
						SHARE_DELETE_SUCCESS: this.$gettext('The share has been successfully deleted'),
						SHARE_ERROR_SUCCESS: this.$gettext('Unable to delete the share'),
						SHARE_HEADER_LIST_TITLE: this.$gettext('Public share'),
						COPY_LINK_BTN: this.$gettext('copy'),
					},
					sharesListDeleteModalTxt:'',
					sharesListDeleteModal: false,
					sharesListEditModal: false,
					selectedShare: undefined,
					showCopied:false

			}
		},
	computed: {
		orderedSharesList: function () {
			return _orderBy(this.shares, function(s) { return s.createdAt}, ['asc']);
		}
	},

	created: function (){

	},
	methods: {

		getHeaderTitle: function(share)
		{
			let title = this.$gettext((share.type && share.type.toLowerCase()=='public'?this.CONSTANTS.SHARE_HEADER_LIST_TITLE:share.receiver.email));			
			return title;
		},
		
		getHeaderSubtitle: function(share)
		{		
			return moment(share.createdAt).format('ll - LTS');
		},

		edit: function(share)
		{
			this.selectedShare = JSON.parse(stringify(share));
			this.sharesListDeleteModalTxt = this.$gettextInterpolate(this.$gettext('Are you sure you want to delete share \'%{share}\'?'), {share: (share.type && share.type.toLowerCase()=='public'?this.CONSTANTS.SHARE_HEADER_LIST_TITLE:share.receiver.email)});
			this.sharesListDeleteModal = true;

		},
		copy: function(s)
		{
			var s = _find(this.shares, {id:s.id});
			if(s)
			{
				s.showCopied = true;
				setTimeout(() => {
					s.showCopied = false;
				}, 1000);
			}
			var copyText = document.querySelector('.shares-list__share-list__header__btn-copy__input-'+s.id);
			copyText.select();
			copyText.setSelectionRange(0, 99999); /*For mobile devices*/
			document.execCommand('copy');
			copyText.blur();
		},
		
		deleteShare: function()
		{
			var _this = this;
			ShareResource.deleteShare(this.selectedShare.id).then(
				function(response){
					_this.eventHub.$emit('setLoading',false);
					_this.eventHub.$emit('displayAlert', _this.CONSTANTS.SHARE_DELETE_SUCCESS, 'success');
					var index = _findIndex(_this.shares, function(o) { return o.id == _this.selectedShare.id });
					_this.shares.splice(index, 1);
					var s = (sessionStorage.getItem('authorization')?sessionStorage:localStorage);
					StorageService.persist(s, 'storage - ownShares', stringify(_this.shares));
					_this.sharesListDeleteModal = false;
				}).catch(
				function(e) {
					console.log(e);
					_this.eventHub.$emit('setLoading',false);
					if(e.response && e.response.status == 401)
					{
						_this.eventHub.$emit('logout');
					}
					else
					{
						_this.eventHub.$emit('displayAlert', _this.CONSTANTS.SHARE_ERROR_SUCCESS+' ('+e.response.status+')');
					}
			});
		},
		
	}
}
